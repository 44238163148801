import Crud from '../views/Setor/Crud'
import Entity from '../views/Setor/Add'

let routes = {
    path: '/setores',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/setores/add',
            name: 'NewSector',
            component: Entity,
            meta: {
                pageTitle: 'Setor',
                access_nivel: ['6', '2'],
                breadcrumb: [
                    {
                        text: 'Adicionar Setor',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/setores/edit/:id?',
            name: 'EditSector',
            component: Entity,
            meta: {
                pageTitle: 'Setor',
                access_nivel: ['6', '2'],
                breadcrumb: [
                    {
                        text: 'Editar Setor',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/setores',
            name: 'list-sectors',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Setores',
                access_nivel: ['6', '2'],
                breadcrumb: [
                    {
                        text: 'Setores',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
