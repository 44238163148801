<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <hr />
              <br />
              <div class="row">
                <div class="col-md-12">
                  <form action>
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label for="Name">
                          <span class="required">*</span> Título:
                        </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="setores.title"
                          placeholder=""
                        />
                      </div>
                      <div class="form-group col-md-12">
                        <label for="Name">
                          <span class="required">*</span> Descrição:
                        </label>
                        <textarea
                          class="form-control"
                          v-model="setores.description"
                          id=""
                          cols="30"
                          rows="10"
                        >
                        </textarea>
                      </div>
                    </div>
                    <br />
                    <span class="required_fields mt-2">
                      <span class="required">*</span>
                      <strong>Campos obrigatórios</strong>
                    </span>
                    <div class="block text-right">
                      <router-link to="/setores" class="btn btn-back">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      <a class="btn btn-add ml-1" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      setores: {
        title: "",
        description: "",
      },

      errors: undefined,
      previewImage: null,
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "setores";

      let fd = self.setores;

      if (self.setores.id) {
        fd._method = "PUT";
        api += "/" + self.setores.id;
      }

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/setores");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getSetores: function (id) {
      const self = this;
      const api = self.$store.state.api + "setores/" + id;

      axios
        .get(api)
        .then((response) => {
          self.setores = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    let id = self.$route.params.id;
    if (id) {
      self.getSetores(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
</style>