<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header"></div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <label>Nome</label>
                  <input
                    v-model="professionals.name"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-3">
                  <label for="cpf">CPF:</label>
                  <input
                    v-model="professionals.cpf"
                    v-mask="'999.999.999-99'"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-3">
                  <label for="rg">RG:</label>
                  <input
                    v-model="professionals.rg"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-4">
                  <label for="advice_id">Conselho:</label>
                  <v-select
                    v-model="professionals.advice_id"
                    class="vselect"
                    :options="advice"
                    :reduce="(advice) => advice.id"
                    :label="'title'"
                  />
                </div>
                <div class="col-md-2">
                  <label for="number_advice">Número do Conselho:</label>
                  <input
                    v-model="professionals.advice"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-4">
                  <label for="specialty_id">Especialidade:</label>
                  <v-select
                    v-model="professionals.specialty_id"
                    class="vselect"
                    :options="specialties"
                    :reduce="(specialties) => specialties.id"
                    :label="'title'"
                  />
                </div>
                <div class="form-group col-md-4">
                  <label for="inputCompany">
                    <span class="required">*</span> CBO:
                  </label>
                  <v-select
                    v-model="professionals.cbo_id"
                    class="vselect"
                    :options="cbos"
                    :reduce="(cbos) => cbos.id"
                    :label="'cbo_nome'"
                  />
                </div>
                <div class="col-md-4">
                  <label>CEP</label>
                  <input
                    type="text"
                    v-model="professionals.cep"
                    class="form-control"
                    v-on:keyup="buscar"
                    v-mask="'99999-999'"
                  />
                </div>
                <div class="col-md-8">
                  <label>Logradouro</label>
                  <input
                    type="text"
                    v-model="professionals.logradouro"
                    class="form-control"
                  />
                </div>
                <div class="col-md-3">
                  <label>Complemento</label>
                  <input
                    type="text"
                    v-model="professionals.complement"
                    class="form-control"
                  />
                </div>
                <div class="col-md-4">
                  <label>Número</label>
                  <input
                    type="text"
                    v-model="professionals.number"
                    class="form-control"
                    id="inputNumero"
                  />
                </div>
                <div class="col-md-3">
                  <label>Bairro</label>
                  <input
                    type="text"
                    v-model="professionals.neighborhood"
                    class="form-control"
                  />
                </div>
                <div class="col-md-3">
                  <label>Cidade</label>
                  <input
                    type="text"
                    v-model="professionals.city"
                    class="form-control"
                  />
                </div>
                <div class="col-md-2">
                  <label>Estado</label>
                  <input
                    type="text"
                    v-model="professionals.state"
                    class="form-control"
                  />
                </div>

                <div class="col-md-10 mt-3">
                  <label>Empresa</label>
                  <v-select
                    v-model="company_selected"
                    class="vselect"
                    :options="companies"
                    :label="'fantasy_name'"
                  />
                </div>

                <div class="col-md-2 mt-5">
                  <button
                    class="btn btn-add"
                    style="margin-top: 12px"
                    @click="addCompany"
                  >
                    Adicionar
                  </button>
                </div>

                <div
                  class="col-md-12 mt-2"
                  v-if="professionals.companies.length > 0"
                >
                  <h3>Empresas Vinculadas</h3>
                </div>
                <div
                  class="col-md-12 mt-1"
                  v-if="professionals.companies.length > 0"
                >
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Razão Social</th>
                        <th scope="col">Nome Fantasia</th>
                        <th scope="col">CNPJ</th>
                        <th scope="col">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(company, index) in professionals.companies"
                        :key="company.id"
                      >
                        <th scope="row">1</th>
                        <td>{{ company.name }}</td>
                        <td>{{ company.fantasy_name }}</td>
                        <td>{{ company.cnpj }}</td>
                        <td>
                          <button
                            class="btn btn-crud delete"
                            @click="removeItem(index)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-12 text-right">
                  <hr />
                  <router-link to="/professionals" class="btn btn-back mr-1">
                    <i class="fa fa-arrow-left" />
                    Voltar
                  </router-link>
                  <a class="btn btn-add" @click="save">
                    Salvar
                    <i class="fa fa-save" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      company_selected: {},
      specialties: [],
      companies: [],
      advice: [],
      cbos: [],
      professionals: {
        name: "",
        cpf: "",
        rg: "",
        advice_id: "",
        specialty_id: "",
        state: "",
        logradouro: "",
        number: "",
        complement: "",
        neighborhood: "",
        city: "",
        cep: "",
        cbo_id: "",
        companies: [],
      },
    };
  },
  computed: {},
  methods: {
    removeItem(index) {
      this.professionals.companies.splice(index, 1);
    },
    addCompany() {
      const self = this;

      self.professionals.companies.push(self.company_selected);
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "professionals";

      let fd = self.professionals;

      if (self.professionals.id) {
        fd._method = "PUT";
        api += "/" + self.professionals.id;
      }

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/professionals");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getSpecialties: function (id) {
      const self = this;
      const api = self.$store.state.api + "specialties?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.specialties = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCbos: function (id) {
      const self = this;
      const api = self.$store.state.api + "cbos?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.cbos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getAdvices: function (id) {
      const self = this;
      const api = self.$store.state.api + "advice?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.advice = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCompanies: function (id) {
      const self = this;
      const api = self.$store.state.api + "companies/";

      axios
        .get(api)
        .then((response) => {
          self.companies = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProfessional: function (id) {
      const self = this;
      const api =
        self.$store.state.api + "professionals/" + id + "?with[]=companies";

      axios
        .get(api)
        .then((response) => {
          self.professionals = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    buscar: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(this.professionals.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + this.professionals.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumero").focus();
              self.naoLocalizado = true;
              return;
            }
            self.endereco = endereco;
            self.professionals.logradouro = self.endereco.logradouro;
            self.professionals.complement = self.endereco.complemento;
            self.professionals.neighborhood = self.endereco.bairro;
            self.professionals.city = self.endereco.localidade;
            self.professionals.state = self.endereco.uf;
            $("#inputNumero").focus();
          }
        );
      }
    },
  },
  mounted: function () {
    const self = this;

    self.getSpecialties();
    self.getAdvices();
    self.getCompanies();
    self.getCbos();
    let id = self.$route.params.id;
    if (id) {
      self.getProfessional(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>