import Crud from '../views/Origin/Crud'
import Entity from '../views/Origin/Add'

let routes = {
    path: '/origins',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/origins/add',
            name: 'NewCbo',
            component: Entity,
            meta: {
                pageTitle: 'Origem',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Adicionar Origem',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/origins/edit/:id?',
            name: 'EditOrigem',
            component: Entity,
            meta: {
                pageTitle: 'Origem',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Editar Origem',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/origins',
            name: 'list-origins',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Origens',

                breadcrumb: [
                    {
                        text: 'Origens',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
