<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card content-crud">
          <div class="ibox-title" style="padding: 16px">
            <h3>Lista de Cancelados</h3>
          </div>
          <div class="row" style="padding: 0px 0px 16px 16px">
            <div class="col-md-3">
              <label>ID</label>
              <input type="text" class="form-control" v-model="filtro.id" />
            </div>
            <div class="col-md-3">
              <label>Nome do Paciente</label>
              <input
                type="text"
                class="form-control"
                v-model="filtro.nome_paciente"
              />
            </div>
            <div class="col-md-3">
              <label>Senha</label>
              <input type="text" class="form-control" v-model="filtro.senha" />
            </div>
            <div class="col-md-2">
              <label>Motivos de Cancelamentos</label>
              <select
                class="form-control"
                v-model="filtro.motivo_cancelamento_id"
              >
                <option value=""></option>
                <option
                  v-for="cancel in cancels"
                  :key="cancel.id"
                  :value="cancel.id"
                >
                  {{ cancel.title }}
                </option>
              </select>
            </div>
            <div class="col-md-3 mt-1">
              <label>Fluxogramas</label>
              <select class="form-control" v-model="filtro.flowchart_id">
                <option value=""></option>
                <option
                  v-for="flowchart in flowcharts"
                  :key="flowchart.id"
                  :value="flowchart.id"
                >
                  {{ flowchart.title }}
                </option>
              </select>
            </div>
            <div class="col-md-3 mt-1">
              <label>Protocolos</label>
              <select class="form-control" v-model="filtro.flowchart_item_id">
                <option value=""></option>
                <option
                  v-for="flowchart_item in flowchart_items"
                  :key="flowchart_item.id"
                  :value="flowchart_item.id"
                >
                  {{ flowchart_item.title }}
                </option>
              </select>
            </div>
            <div class="col-md-3 mt-1">
              <label>Data da Entrada no Setor</label>
              <input
                type="date"
                class="form-control"
                v-model="filtro.created_at"
              />
            </div>
            <div class="row" style="margin-top: 40px">
              <div class="col-md-2">
                <button class="btn btn-primary" @click="getEntity">
                  Filtrar
                </button>
              </div>
            </div>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive-sm">
                  <table
                    role="table"
                    aria-busy="false"
                    aria-colcount="4"
                    class="table b-table"
                  >
                    <thead role="rowgroup" class="">
                      <tr role="row" class="">
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="1"
                          class=""
                        >
                          <div>Classificação</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="1"
                          class=""
                        >
                          <div>Senha</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="2"
                          class=""
                        >
                          <div>Nome</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="3"
                          class=""
                        >
                          <div>Fluxograma</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="4"
                          class=""
                        >
                          <div>Data/Hora no Setor</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="4"
                          class=""
                        >
                          <div>Motivo Cancelamento</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="4"
                          class=""
                        >
                          <div>Usuário do Cancelamento</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="4"
                          class=""
                        >
                          <div>Ações</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup">
                      <tr
                        role="row"
                        class=""
                        v-for="atendimento in atendimentos"
                        :key="atendimento.id"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          <router-link
                            :to="'/triage/view/' + atendimento.id"
                            tag="button"
                            :class="
                              classificacaoColor(atendimento.classificacao)
                            "
                          >
                            {{ classificacaoLabel(atendimento.classificacao) }}
                          </router-link>
                        </td>
                        <td aria-colindex="1" role="cell" class="">
                          {{ atendimento.senha }}
                        </td>
                        <td aria-colindex="2" role="cell" class="">
                          {{
                            atendimento.paciente
                              ? atendimento.paciente.nome_social
                                ? atendimento.paciente.nome_social
                                : atendimento.paciente.nome_paciente
                              : "Informação não disponível"
                          }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            atendimento.flowchart
                              ? atendimento.flowchart.title +
                                " / " +
                                atendimento.flowchart_item.title
                              : "Não possui fluxograma"
                          }}
                        </td>
                        <td aria-colindex="4" role="cell" class="">
                          {{ atendimento.created_at_f }}
                        </td>
                        <td aria-colindex="4" role="cell" class="">
                          {{
                            atendimento.cancel ? atendimento.cancel.title : ""
                          }}
                        </td>
                        <td aria-colindex="4" role="cell" class="">
                          {{ atendimento.user ? atendimento.user.name : "" }}
                        </td>
                        <td aria-colindex="4" role="cell" class="">
                          <router-link
                            :to="'/triage/view/' + atendimento.id"
                            tag="button"
                            class="btn btn-crud info mr-1"
                            alt="Visualizar"
                          >
                            <i class="fas fa-eye"></i>
                          </router-link>
                          <!-- <button
                            class="btn btn-crud edit"
                            alt="Chamar no Painel"
                            @click="imprimirFicha(atendimento)"
                          >
                            <i class="fas fa-print"></i>
                          </button> -->
                        </td>
                      </tr>
                      <tr v-if="atendimentos.length == 0">
                        <td colspan="8" class="centered mt-2">
                          Nenhum atendimento na fila de triagem.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="col-md-6 mx-auto d-flex justify-content-center align-items-center"
              >
                <pagination
                  :records="total"
                  :options="options"
                  :per-page="15"
                  @paginate="getEntity"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Pagination } from "vue-pagination-2";
export default {
  data() {
    return {
      atendimentos: [],
      cancels: [],
      flowcharts: [],
      flowchart_items: [],
      filtro: {},
      options: {
        texts: {
          count: "Mostrando {from} de {to} até {count} de registros",
        },
        chunk: 4,
        chunksNavigation: "fixed",
      },
      total: 0,
      page: 1,
    };
  },
  components: { Pagination },
  methods: {
    imprimirFicha(obj) {
      const self = this;
      console.log(obj);
      let url = self.$store.state.url + "atendimento/impressao/" + obj.id;
      window.open(url, "_blank").focus();
    },
    classificacaoLabel(classificacao) {
      if (classificacao == 1) {
        return "Vermelho";
      }

      if (classificacao == 2) {
        return "Laranja";
      }

      if (classificacao == 3) {
        return "Amarelo";
      }

      if (classificacao == 4) {
        return "Verde";
      }

      if (classificacao == 5) {
        return "Azul";
      }

      if (classificacao == 6) {
        return "Branco";
      }

      if (classificacao == 999) {
        return "Cancelado";
      }

      if (!classificacao) {
        return "Não Classificado";
      }
    },
    classificacaoColor(classificacao) {
      if (classificacao == 1) {
        return "btn-classificacao vermelho";
      }

      if (classificacao == 2) {
        return "btn-classificacao laranja";
      }

      if (classificacao == 3) {
        return "btn-classificacao amarelo";
      }

      if (classificacao == 4) {
        return "btn-classificacao verde";
      }

      if (classificacao == 5) {
        return "btn-classificacao azul";
      }

      if (classificacao == 6) {
        return "btn-classificacao branco";
      }

      if (classificacao == 999) {
        return "btn-classificacao vermelho";
      }

      if (!classificacao) {
        return "btn-classificacao cinza";
      }
    },
    diffIdade(data_nascimento) {
      const self = this;

      var a = moment();
      var b = moment(data_nascimento);

      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");

      if (years < 100) {
        return years + " anos, " + months + " meses, " + days + " dias";
      }
    },
    getEntity: function (page) {
      const self = this;

      self.page = page;

      let api =
        self.$store.state.api +
        "get-atendimentos?status=3&with[]=flowchart&with[]=flowchart_item&with[]=cancel&with[]=user&with[]=paciente&page=" +
        self.page;

      self.$loading(true);

      axios
        .post(api, self.filtro)
        .then((response) => {
          self.atendimentos = response.data.data;
          self.total = response.data.total;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getFlowcharts: function () {
      const self = this;
      let api = self.$store.state.api + "flowcharts?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.flowcharts = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getFlowchartItems: function () {
      const self = this;
      let api =
        self.$store.state.api + "flowchart_items?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.flowchart_items = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCancelReasons: function () {
      const self = this;
      let api =
        self.$store.state.api + "cancellation_reasons?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.cancels = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    search: _.debounce(function (e) {
      this.getEntity();
    }, 800),
    filtered_de(date) {
      this.created_at = date;
    },
  },
  mounted() {
    const self = this;

    self.getEntity();
    self.getCancelReasons();
    self.getFlowcharts();
    self.getFlowchartItems();
  },
  created() {},
};
</script>

<style>
</style>
