import Crud from '../views/RazoesEntrada/Crud'
import Entity from '../views/RazoesEntrada/Add'

let routes = {
    path: '/razoes_entrada',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/razoes_entrada/add',
            name: 'NewRazaoEntrada',
            component: Entity,
            meta: {
                pageTitle: 'Razão de Entrada',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Adicionar Razão de Entrada',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/razoes_entrada/edit/:id?',
            name: 'EditRazaoEntrada',
            component: Entity,
            meta: {
                pageTitle: 'Razão de Entrada',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Editar Razão de Entrada',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/razoes_entrada',
            name: 'list-razoes',
            access_nivel: ['6'],
            component: Crud,
            meta: {
                pageTitle: 'Lista de Razões de Entrada',
                breadcrumb: [
                    {
                        text: 'Razões de Entrada',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
