import Crud from '../views/Specialty/Crud'
import Entity from '../views/Specialty/Add'

let routes = {
    path: '/specialties',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/specialties/add',
            name: 'NewSpecialty',
            component: Entity,
            meta: {
                pageTitle: 'Especialidade',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Adicionar Especialidade',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/specialties/edit/:id?',
            name: 'EditSpecialty',
            component: Entity,
            meta: {
                pageTitle: 'Especialidade',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Editar Especialidade',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/specialties',
            name: 'list-specialties',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Especialidades',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Especialidades',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
