<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <hr />
              <br />
              <div class="row">
                <div class="col-md-12">
                  <form action>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="Name">
                          <span class="required">*</span> Razão Social:
                        </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="companies.name"
                          placeholder=""
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="fantasy_name">
                          <span class="required">*</span> Nome Fantasia:
                        </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="fantasy_name"
                          class="form-control"
                          v-model="companies.fantasy_name"
                          placeholder=""
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="cnpj">
                          <span class="required">*</span> CNPJ:
                        </label>
                        <input
                          autocomplete="new-password"
                          class="form-control"
                          id="cnpj"
                          placeholder=""
                          type="text"
                          v-model="companies.cnpj"
                          v-mask="'99.999.999/9999-99'"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="Email">
                          <span class="required">*</span> E-mail:
                        </label>
                        <input
                          autocomplete="new-password"
                          class="form-control"
                          id="Email"
                          placeholder=""
                          type="text"
                          v-model="companies.email"
                        />
                      </div>
                      <div class="col-md-2">
                        <label><span class="required">*</span> CEP</label>
                        <input
                          type="text"
                          v-model="companies.cep"
                          class="form-control"
                          v-on:keyup="buscar"
                          v-mask="'99999-999'"
                        />
                      </div>
                      <div class="col-md-6">
                        <label>
                          <span class="required">*</span> Logradouro</label
                        >
                        <input
                          type="text"
                          v-model="companies.street"
                          class="form-control"
                        />
                      </div>
                      <div class="col-md-2">
                        <label> <span class="required">*</span> CNES</label>
                        <input
                          type="number"
                          v-model="companies.cnes"
                          class="form-control"
                        />
                      </div>
                      <div class="col-md-2">
                        <label><span class="required">*</span> Número</label>
                        <input
                          type="text"
                          v-model="companies.number"
                          class="form-control"
                        />
                      </div>
                      <div class="col-md-3">
                        <label>Complemento</label>
                        <input
                          type="text"
                          v-model="companies.complement"
                          class="form-control"
                        />
                      </div>
                      <div class="col-md-3">
                        <label><span class="required">*</span> Bairro</label>
                        <input
                          type="text"
                          v-model="companies.neighborhood"
                          class="form-control"
                        />
                      </div>
                      <div class="col-md-4">
                        <label><span class="required">*</span> Cidade</label>
                        <input
                          type="text"
                          v-model="companies.city"
                          class="form-control"
                        />
                      </div>
                      <div class="col-md-2">
                        <label><span class="required">*</span> Estado</label>
                        <input
                          type="text"
                          v-model="companies.uf"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <br />
                    <span class="required_fields mt-2">
                      <span class="required">*</span>
                      <strong>Campos obrigatórios</strong>
                    </span>
                    <div class="block text-right">
                      <router-link to="/companies" class="btn btn-back">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      <a class="btn btn-add ml-1" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      departments: [],
      companies: {
        name: "",
        email: "",
        cellphone: "",
        telphone: "",
        photo: "",
        access_nivel: "",
        company_id: "",
        department_id: "",
        password: "",
        password_confirmation: "",
        cep: "",
        street: "",
        number: "",
        complement: "",
        neighborhood: "",
        city: "",
        uf: "",
        cns: "",
      },

      errors: undefined,
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "companies";

      let fd = self.companies;

      if (self.companies.id) {
        fd._method = "PUT";
        api += "/" + self.companies.id;
      }

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/companies");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    buscar: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(this.companies.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + this.companies.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumero").focus();
              self.naoLocalizado = true;
              return;
            }
            self.endereco = endereco;
            self.companies.street = self.endereco.logradouro;
            self.companies.neighborhood = self.endereco.bairro;
            self.companies.uf = self.endereco.uf;
            self.companies.city = self.endereco.localidade;
            $("#inputNumero").focus();
          }
        );
      }
    },
    getCompanies: function (id) {
      const self = this;
      const api = self.$store.state.api + "companies/" + id;

      axios
        .get(api)
        .then((response) => {
          self.companies = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    let id = self.$route.params.id;
    if (id) {
      self.getCompanies(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>