import Crud from '../views/CancellationReason/Crud'
import Entity from '../views/CancellationReason/Add'

let routes = {
    path: '/cancellation_reasons',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/cancellation_reasons/add',
            name: 'NewCancellationReason',
            component: Entity,
            meta: {
                pageTitle: 'Motivo de Cancelamento',
                access_nivel: ['6', '2'],
                breadcrumb: [
                    {
                        text: 'Adicionar Motivo de Cancelamento',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/cancellation_reasons/edit/:id?',
            name: 'EditCancellationReason',
            component: Entity,
            meta: {
                pageTitle: 'Motivo de Cancelamento',
                access_nivel: ['6', '2'],
                breadcrumb: [
                    {
                        text: 'Editar Motivo de Cancelamento',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/cancellation_reasons',
            name: 'list-cancellation-reasons',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Motivos de Cancelamentos',
                access_nivel: ['6', '2'],
                breadcrumb: [
                    {
                        text: 'Motivos de Cancelamentos',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
