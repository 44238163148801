import Crud from '../views/NecessidadesEspeciais/Crud'
import Entity from '../views/NecessidadesEspeciais/Add'

let routes = {
    path: '/necessidades_especiais',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/necessidades_especiais/add',
            name: 'NewRazaoEntrada',
            component: Entity,
            meta: {
                pageTitle: 'Necessidade Especial',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Adicionar Necessidade Especial',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/necessidades_especiais/edit/:id?',
            name: 'EditRazaoEntrada',
            component: Entity,
            meta: {
                pageTitle: 'Necessidade Especial',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Editar Necessidade Especial',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/necessidades_especiais',
            name: 'list-necessidades',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Necessidades Especiais',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Necessidades Especiais',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
