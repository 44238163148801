import Crud from '../views/Advice/Crud'
import Entity from '../views/Advice/Add'

let routes = {
    path: '/advice',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/advice/add',
            name: 'NewAdvice',
            component: Entity,
            meta: {
                pageTitle: 'Conselho',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Adicionar Conselho',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/advice/edit/:id?',
            name: 'EditAdvice',
            component: Entity,
            meta: {
                pageTitle: 'Conselho',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Editar Conselho',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/advice',
            name: 'list-advice',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Conselhos',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Conselhos',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
