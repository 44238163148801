<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Visualizar Fluxograma</div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <label> Título</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    v-model="flowcharts.title"
                  />
                </div>
              </div>
              <hr />
              <div class="row" v-if="flowcharts.specialty_to_flowchart">
                <div class="col-md-12">
                  <div class="col-md-12">
                    <table class="table table-bordered" style="width: 100%">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 100%">
                            Nome da Especialidade
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        v-if="flowcharts.specialty_to_flowchart.length > 0"
                      >
                        <tr
                          v-for="specialty in flowcharts.specialty_to_flowchart"
                          :key="specialty.id"
                        >
                          <td v-if="specialty.specialty.length > 0">
                            {{ specialty.specialty[0].title }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else class="centered">
                        <tr>
                          <td>Nenhuma especialidade vinculada.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-4">
                  <label>Sexo</label>
                  <select class="form-control" v-model="flowcharts.gender">
                    <option value="0">Ambos</option>
                    <option value="1">Masculino</option>
                    <option value="2">Feminino</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <label> Faixa etária de</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="flowcharts.in"
                  />
                </div>
                <div class="col-md-4">
                  <label> Faixa etária até</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="flowcharts.to"
                  />
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-md-4">
                  <label>Protocolo</label>
                  <v-select
                    disabled
                    :options="protocols"
                    label="title"
                    :reduce="(protocol) => protocol.id"
                    v-model="flowcharts.protocol_id"
                  ></v-select>
                </div>
              </div>

              <div class="row mt-1">
                <div class="col-md-6">
                  <label> Veja também</label>
                  <textarea
                    disabled
                    class="form-control"
                    cols="30"
                    rows="10"
                    v-model="flowcharts.view_more"
                  ></textarea>
                </div>
                <div class="col-md-6">
                  <label> Notas do fluxograma</label>
                  <textarea
                    disabled
                    class="form-control"
                    cols="30"
                    rows="10"
                    v-model="flowcharts.notes"
                  ></textarea>
                </div>
              </div>
              <div class="row mt-2 mb-2">
                <div
                  class="col-md-12"
                  style="font-weight: bold; font-size: 16px"
                >
                  Discriminadores
                </div>
              </div>
              <div class="row" v-if="discriminators.length > 0">
                <div class="col-md-3">Título</div>
                <div class="col-md-3">Explicação</div>
                <div class="col-md-1">Sexo</div>
                <div class="col-md-3">Faixa Etária (De/Até)</div>
                <div class="col-md-1">Tipo</div>
              </div>
              <div class="row mt-3 mb-4 centered" v-else>
                <div class="col-md-12">
                  Nenhum discriminador específico cadastrado.
                </div>
              </div>
              <div
                class="row mt-1"
                v-for="discriminator in flowcharts.discriminators"
                :key="discriminator.id"
              >
                <div class="col-md-3">
                  <input
                    :disabled="discriminator.disabled"
                    type="text"
                    class="form-control"
                    v-model="discriminator.title"
                  />
                </div>
                <div class="col-md-7">
                  <div class="row">
                    <div class="col-md-5">
                      <textarea
                        :disabled="discriminator.disabled"
                        class="form-control"
                        v-model="discriminator.description"
                        rows="5"
                      />
                    </div>
                    <div class="col-md-2">
                      <select
                        :disabled="discriminator.disabled"
                        class="form-control"
                        v-model="discriminator.sexo"
                      >
                        <option value="0">Ambos</option>
                        <option value="1">M</option>
                        <option value="2">F</option>
                      </select>
                    </div>
                    <div class="col-md-1">
                      <input
                        :disabled="discriminator.disabled"
                        type="text"
                        class="form-control"
                        v-model="discriminator.faixa_etaria_de"
                        style="width: 60px"
                      />
                    </div>
                    <div class="col-md-1">
                      <input
                        :disabled="discriminator.disabled"
                        type="text"
                        class="form-control"
                        v-model="discriminator.faixa_etaria_ate"
                        style="width: 60px"
                      />
                    </div>
                    <div class="col-md-2">
                      <select
                        :disabled="discriminator.disabled"
                        class="form-control"
                        v-model="discriminator.tipo_idade"
                      >
                        <option value="A">Anos</option>
                        <option value="M">Meses</option>
                        <option value="D">Dias</option>
                      </select>
                    </div>
                    <div class="col-md-1">
                      <button
                        v-if="!discriminator.disabled"
                        class="btn btn-crud info"
                        @click="
                          saveDiscriminator(discriminator),
                            (discriminator.disabled = true)
                        "
                        style="margin-top: 5px"
                      >
                        <i class="fas fa-save"></i>
                      </button>
                      <button
                        v-else
                        class="btn btn-crud edit"
                        @click="discriminator.disabled = false"
                        style="margin-top: 5px"
                      >
                        <i class="fas fa-pencil-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <select
                    v-model="discriminator.type"
                    class="form-control"
                    v-on:input="saveDiscriminator(discriminator)"
                  >
                    <option value=""></option>
                    <option value="G">Escala de Glasgow</option>
                    <option value="GC">Glicemia capilar</option>
                    <option value="P">Pressão</option>
                    <option value="R">Régua de dor</option>
                    <option value="S">Saturação</option>
                    <option value="T">Temperatura</option>
                    <option value="B">Pulso</option>
                    <option value="Z">Sepse</option>
                  </select>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12">
                  <button
                    class="btn btn-add"
                    data-toggle="modal"
                    data-target="#modalAdicionarDiscriminador"
                  >
                    Adicionar Discriminador
                  </button>
                </div>
              </div>
              <br />
              <div
                class="col-md-12 mt-2"
                style="font-weight: bold; font-size: 25px; text-align: center"
              >
                {{ flowcharts.title }}
              </div>
              <div
                class="row"
                v-if="
                  flowcharts.items.filter((color) => color.color_id == 1)
                    .length > 0
                "
              >
                <div class="col-md-5">
                  <div id="triangulo" align="center">
                    <div
                      id="triangulo-para-cima"
                      style="border-bottom-color: #fc0303"
                    ></div>
                    <div
                      id="triangulo-para-baixo"
                      style="border-top-color: #fc0303"
                    ></div>
                  </div>
                  <div class="card-fluxogram">
                    <span
                      v-for="item in flowcharts.items.filter(
                        (color) => color.color_id == 1
                      )"
                      :key="item.id"
                    >
                      {{ item.title }} <br />
                    </span>
                  </div>
                </div>
                <div class="col-md-4">
                  <img src="@/assets/images/seta.png" class="seta-direita" />
                </div>
                <div class="col-md-3">
                  <div class="box red">Vermelho</div>
                </div>
              </div>
              <div
                class="row"
                style="max-height: 130px"
                v-if="items.filter((color) => color.color_id == 1).length > 0"
              >
                <div class="col-md-5" align="center">
                  <img
                    src="@/assets/images/seta_baixo.png"
                    class="seta-baixo"
                  />
                </div>
              </div>
              <div
                class="row"
                v-if="items.filter((color) => color.color_id == 2).length > 0"
              >
                <div class="col-md-5">
                  <div id="triangulo" align="center">
                    <div
                      id="triangulo-para-cima"
                      style="border-bottom-color: #fc7b03"
                    ></div>
                    <div
                      id="triangulo-para-baixo"
                      style="border-top-color: #fc7b03"
                    ></div>
                  </div>
                  <div class="card-fluxogram">
                    <span
                      v-for="item in flowcharts.items.filter(
                        (color) => color.color_id == 2
                      )"
                      :key="item.id"
                    >
                      {{ item.title }} <br />
                    </span>
                  </div>
                </div>
                <div class="col-md-4">
                  <img src="@/assets/images/seta.png" class="seta-direita" />
                </div>
                <div class="col-md-3">
                  <div class="box orange">Laranja</div>
                </div>
              </div>
              <div
                class="row"
                style="max-height: 130px"
                v-if="items.filter((color) => color.color_id == 2).length > 0"
              >
                <div class="col-md-5" align="center">
                  <img
                    src="@/assets/images/seta_baixo.png"
                    class="seta-baixo"
                  />
                </div>
              </div>
              <div
                class="row"
                v-if="items.filter((color) => color.color_id == 3).length > 0"
              >
                <div class="col-md-5">
                  <div id="triangulo" align="center">
                    <div
                      id="triangulo-para-cima"
                      style="border-bottom-color: #f5ed02"
                    ></div>
                    <div
                      id="triangulo-para-baixo"
                      style="border-top-color: #f5ed02"
                    ></div>
                  </div>
                  <div class="card-fluxogram">
                    <span
                      v-for="item in flowcharts.items.filter(
                        (color) => color.color_id == 3
                      )"
                      :key="item.id"
                    >
                      {{ item.title }} <br />
                    </span>
                  </div>
                </div>
                <div class="col-md-4">
                  <img src="@/assets/images/seta.png" class="seta-direita" />
                </div>
                <div class="col-md-3">
                  <div class="box yellow">Amarelo</div>
                </div>
              </div>
              <div
                class="row"
                style="max-height: 130px"
                v-if="items.filter((color) => color.color_id == 3).length > 0"
              >
                <div class="col-md-5" align="center">
                  <img
                    src="@/assets/images/seta_baixo.png"
                    class="seta-baixo"
                  />
                </div>
              </div>
              <div
                class="row"
                v-if="
                  flowcharts.items.filter((color) => color.color_id == 4)
                    .length > 0
                "
              >
                <div class="col-md-5">
                  <div id="triangulo" align="center">
                    <div
                      id="triangulo-para-cima"
                      style="border-bottom-color: #00923f"
                    ></div>
                    <div
                      id="triangulo-para-baixo"
                      style="border-top-color: #00923f"
                    ></div>
                  </div>
                  <div class="card-fluxogram">
                    <span
                      v-for="item in flowcharts.items.filter(
                        (color) => color.color_id == 4
                      )"
                      :key="item.id"
                    >
                      {{ item.title }} <br />
                    </span>
                  </div>
                </div>
                <div class="col-md-4">
                  <img src="@/assets/images/seta.png" class="seta-direita" />
                </div>
                <div class="col-md-3">
                  <div class="box green">Verde</div>
                </div>
              </div>

              <div
                class="row"
                style="max-height: 130px"
                v-if="
                  flowcharts.items.filter((color) => color.color_id == 4)
                    .length > 0
                "
              >
                <div class="col-md-5" align="center">
                  <img
                    src="@/assets/images/seta_baixo.png"
                    class="seta-baixo"
                  />
                </div>
              </div>
              <div
                class="row"
                v-if="
                  flowcharts.items.filter((color) => color.color_id == 4)
                    .length == 0
                "
              >
                <div class="col-md-5" align="center">
                  <div class="final-box green">Verde</div>
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-md-5" align="center">
                  <div class="final-box blue">Azul</div>
                </div>
              </div>
              <br />
              <div class="block text-right">
                <router-link to="/flowcharts" class="btn btn-back">
                  <i class="fa fa-arrow-left"></i> Voltar
                </router-link>
                <button class="ml-3 btn btn-back" @click="saveFlowchart">
                  <i class="fa fa-save"></i> Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Add Discriminador -->
    <div
      class="modal fade"
      id="modalAdicionarDiscriminador"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAdicionarDiscriminadorTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalAdicionarDiscriminadorTitle">
              Adicionar Discriminador
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4"><span>*</span> Título</div>
              <div class="col-md-5"><span>*</span> Explicação</div>
              <div class="col-md-2"><span>*</span> Cor</div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <input type="text" class="form-control" v-model="item.title" />
              </div>
              <div class="col-md-5">
                <input
                  type="text"
                  class="form-control"
                  v-model="item.explicacao"
                />
              </div>

              <div class="col-md-2" style="margin-top: 5px">
                <button
                  :class="
                    item.color_id == 1
                      ? 'btn-color red-selected'
                      : 'btn-color red'
                  "
                  @click="item.color_id = 1"
                ></button>
                <button
                  :class="
                    item.color_id == 2
                      ? 'btn-color orange-selected'
                      : 'btn-color orange'
                  "
                  @click="item.color_id = 2"
                ></button>
                <button
                  :class="
                    item.color_id == 3
                      ? 'btn-color yellow-selected'
                      : 'btn-color yellow'
                  "
                  @click="item.color_id = 3"
                ></button>
                <button
                  :class="
                    item.color_id == 4
                      ? 'btn-color green-selected'
                      : 'btn-color green'
                  "
                  @click="item.color_id = 4"
                ></button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="item = { color_id: '' }"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="addDiscriminator"
            >
              Adicionar Discriminador
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      specialties: [],
      discriminators: [],
      items: [],
      specialties: [],
      flowcharts: {
        discriminators: [],
        items: [],
        specialties: [],
        specialty_to_flowcharts: [],
      },
      flowchart: {
        discriminators: [],
        items: [],
        specialties: [],
      },
      specialty_selecionado: {},
      protocols: [],
      specialty_to_flowcharts: [],
      errors: undefined,
      previewImage: null,
      item: {
        color_id: "",
      },
    };
  },
  computed: {},
  methods: {
    addDiscriminator() {
      const self = this;
      const api = self.$store.state.api + "flowchart_discriminators/save";

      self.item.flowchart_id = self.flowcharts.id;

      axios
        .post(api, self.item)
        .then((response) => {
          let id = self.$route.params.id;
          if (id) {
            self.getFlowcharts(id);
            self.getSpecialties(id);
            self.getProtocols(id);
            self.getDiscriminators(id);
            self.getItems(id);
            self.getSpecialtyFlowcharts(id);
          }
          self.$message("Sucesso", "Discriminador atualizado", "success");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    saveFlowchart() {
      setTimeout(() => {
        const self = this;
        const api = self.$store.state.api + "flowcharts/" + self.flowcharts.id;

        self.flowcharts._method = "put";

        axios
          .post(api, self.flowcharts)
          .then((response) => {
            self.$message("Sucesso", "Fluxograma atualizado", "success");
          })
          .catch((error) => {
            self.$message(null, error.response.data, "error");
          });
      }, 1000);
    },
    saveDiscriminator(obj) {
      setTimeout(() => {
        const self = this;
        const api =
          self.$store.state.api + "flowchart_discriminators/" + obj.id;

        obj._method = "put";

        axios
          .post(api, obj)
          .then((response) => {
            self.$message("Sucesso", "Discriminador atualizado", "success");
          })
          .catch((error) => {
            self.$message(null, error.response.data, "error");
          });
      }, 1000);
    },
    getSpecialties: function (id) {
      const self = this;
      const api = self.$store.state.api + "specialties?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.specialties = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getSpecialtyFlowcharts: function (id) {
      const self = this;
      const api =
        self.$store.state.api + "specialty_to_flowcharts?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.specialty_to_flowcharts = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProtocols: function (id) {
      const self = this;
      const api = self.$store.state.api + "protocols?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.protocols = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getDiscriminators: function (id) {
      const self = this;
      const api =
        self.$store.state.api + "flowchart_discriminators?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.discriminators = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getItems: function (id) {
      const self = this;
      const api = self.$store.state.api + "flowchart_items?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.items = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getFlowcharts: function (id) {
      const self = this;
      const api =
        self.$store.state.api +
        "flowcharts/" +
        id +
        "?with[]=specialty_to_flowchart&with[]=specialty&with[]=items&with[]=discriminators&with[]=protocol";

      axios
        .get(api)
        .then((response) => {
          self.flowcharts = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    let id = self.$route.params.id;
    if (id) {
      self.getFlowcharts(id);
      self.getSpecialties(id);
      self.getProtocols(id);
      self.getDiscriminators(id);
      self.getItems(id);
      self.getSpecialtyFlowcharts(id);
    }
  },
  components: {},
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
.vs__dropdown-toggle {
  padding: 5px 0 20px !important;
}
</style>