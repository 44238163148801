<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <hr />
              <br />
              <div class="row">
                <div class="col-md-12">
                  <form action>
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label for="Name">
                          <span class="required">*</span> Título:
                        </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="specialties.title"
                          placeholder=""
                        />
                      </div>
                      <div class="form-group col-md-12">
                        <label for="Name"> Descrição: </label>
                        <textarea
                          class="form-control"
                          v-model="specialties.description"
                          id=""
                          cols="30"
                          rows="10"
                        >
                        </textarea>
                      </div>
                    </div>
                    <br />
                    <span class="required_fields mt-2">
                      <span class="required">*</span>
                      <strong>Campos obrigatórios</strong>
                    </span>
                    <div class="block text-right">
                      <router-link to="/specialties" class="btn btn-back">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      <a class="btn btn-add ml-1" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      specialties: {
        title: "",
        description: "",
      },

      errors: undefined,
      previewImage: null,
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "specialties";

      let fd = self.specialties;

      if (self.specialties.id) {
        fd._method = "PUT";
        api += "/" + self.specialties.id;
      }

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/specialties");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    buscar: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(this.specialties.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + this.specialties.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumero").focus();
              self.naoLocalizado = true;
              return;
            }
            self.endereco = endereco;
            self.specialties.street = self.endereco.logradouro;
            self.specialties.neighborhood = self.endereco.bairro;
            self.specialties.state = self.endereco.uf;
            self.specialties.city = self.endereco.localidade;
            $("#inputNumero").focus();
          }
        );
      }
    },
    getSpecialties: function (id) {
      const self = this;
      const api = self.$store.state.api + "specialties/" + id;

      axios
        .get(api)
        .then((response) => {
          self.specialties = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    let id = self.$route.params.id;
    if (id) {
      self.getSpecialties(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
</style>