<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <hr />
              <br />
              <div class="row">
                <div class="col-md-12">
                  <form action>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="Name">
                          <span class="required">*</span> Nome:
                        </label>
                        <input autocomplete="new-password" type="text" id="Name" class="form-control"
                          v-model="origin.title" />
                      </div>



                      <div class="form-group col-md-6">
                        <label for="Name">
                          <span class="required">*</span> Status:
                        </label>
                        <select v-model="origin.status" class="form-control">
                          <option :value="1">Ativo</option>
                          <option :value="0">Inativo</option>
                        </select>
                      </div>
                    </div>

                    <span class="required_fields">
                      <span class="required">*</span>
                      <strong>Campos obrigatórios</strong>
                    </span>
                    <div class="block text-right">
                      <router-link to="/origins" class="btn btn-back mr-1">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      <a class="btn btn-add" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      origin: {
        status: "1"
      },


      errors: undefined,
      previewImage: null,
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "origins";

      let fd = self.origin;

      if (self.origin.id) {
        fd._method = "PUT";
        api += "/" + self.origin.id;
      }

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/origins");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getOrigins: function (id) {
      const self = this;
      const api = self.$store.state.api + "origins/" + id;

      axios
        .get(api)
        .then((response) => {
          self.origin = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    let id = self.$route.params.id;
    if (id) {
      self.getOrigins(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped></style>