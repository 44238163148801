let env = {};

env.baseURL = 'https://api.triagem-hml.ares-saude.com.br/api/v1/';
env.normalURL = 'https://api.triagem-hml.ares-saude.com.br/';
env.wsHost = '54.207.61.51';
env.wsPort = '6001';

// env.baseURL = 'http://api.triagem.ares-saude.com.br/api/v1/';
// env.normalURL = 'http://api.triagem.ares-saude.com.br/';
// env.webserviceHost = '179.190.28.108';
// env.webservicePort = '6001';

// env.baseURL = 'http://api.triagem-sp.ares-saude.com.br/api/v1/';
// env.normalURL = 'http://api.triagem-sp.ares-saude.com.br/';
// env.webserviceHost = '179.190.28.108';
// env.webservicePort = '6001';

// env.baseURL = 'http://localhost:8000/api/v1/';
// env.normalURL = 'http://localhost:8000/';
// env.webserviceHost = 'localhost';
// env.webservicePort = '6001';

export default env
