<template>
  <div>
    <div class="row">
      <div class="col-md-7 col-lg-8 col-xl-9 col-12">
        <div class="card" :key="classificacaoLabel" :style="classificacaoColor">
          <div class="card-body">
            <div class="row">
              <div
                class="d-flex justify-content-between flex-column col-xl-7 col-21"
              >
                <div class="d-flex justify-content-start">
                  <span
                    class="b-avatar badge-light-danger rounded"
                    :style="
                      userDataFullscreen
                        ? 'width: 104px; height: 104px'
                        : 'width: 40px; height: 40px'
                    "
                    ><span class="b-avatar-img"
                      ><img
                        src="@/assets/images/avatars/default.png"
                        alt="avatar" /></span
                  ></span>
                  <div class="d-flex flex-column ml-1">
                    <div :class="userDataFullscreen ? 'mb-1' : ''">
                      <h4 class="mb-0">
                        {{ atendimento.paciente.nome_paciente }}
                      </h4>
                      <span class="card-text">{{ diffIdade }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex align-items-center mt-2"
                  v-if="userDataFullscreen"
                >
                  <div class="d-flex align-items-center mr-2">
                    <span class="b-avatar badge-light-primary rounded"
                      ><i class="far fa-clock" style="font-size: 18px"></i
                    ></span>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ atendimento.tempo_espera_unidade }} min
                      </h5>
                      <small>Tempo de espera na unidade</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-12" v-if="userDataFullscreen">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <i class="fas fa-venus-mars mr-75"></i>
                      <span class="font-weight-bold">Sexo</span>
                    </th>
                    <td class="pb-50">
                      {{ atendimento.paciente.sexo_f }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <i class="fas fa-id-card mr-75"></i>
                      <span class="font-weight-bold">CNS</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        atendimento.paciente.cns
                          ? atendimento.paciente.cns
                          : "Não informado"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <i class="fas fa-calendar-alt mr-75"></i>
                      <span class="font-weight-bold">Nascimento</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        atendimento.paciente.data_nascimento
                          ? atendimento.paciente.data_nascimento_f
                          : "Não informado"
                      }}
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14px"
                        height="14px"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="mr-75 feather feather-phone"
                      >
                        <path
                          d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                        ></path>
                      </svg>
                      <span class="font-weight-bold">Telefone</span>
                    </th>
                    <td>
                      {{
                        atendimento.paciente.telefone
                          ? atendimento.paciente.telefone
                          : "Não informado"
                      }}
                    </td>
                  </tr>
                  <tr class="mt-1">
                    <th class="pb-50">
                      <i class="fas fa-id-card mr-75"></i>
                      <span class="font-weight-bold">Origem</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{
                        atendimento.origin_id
                          ? atendimento.origin.title
                          : "Não informado"
                      }}
                    </td>
                  </tr>
                </table>
                <div class="d-flex align-items-center mt-2">
                  <span class="b-avatar badge-light-success rounded"
                    ><i class="far fa-clock" style="font-size: 18px"></i
                  ></span>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ atendimento.tempo_espera_setor }} min
                    </h5>
                    <small>Tempo de espera neste setor</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-lg-4 col-xl-3 col-12">
        <div class="card border-primary">
          <div
            class="card-header d-flex justify-content-between align-items-center pt-75 pb-25"
            :class="!userDataFullscreen ? 'mb-2' : ''"
          >
            <h5 class="mb-0" style="font-weight: bold; font-size: 20px">
              {{ atendimento.senha }}
            </h5>
            <small class="text-muted w-100">Senha</small>
          </div>
          <div class="card-body" v-if="userDataFullscreen">
            <ul class="list-unstyled my-1">
              <li>
                <span class="align-middle"
                  ><h5 class="mb-0" style="font-weight: bold; font-size: 14px">
                    {{ atendimento.data_entrada_f }}
                  </h5>
                  <small class="text-muted w-100"
                    >Data de entrada na unidade</small
                  ></span
                >
              </li>
              <li class="mt-2" v-if="userDataFullscreen">
                <span class="align-middle"
                  ><h5 class="mb-0" style="font-weight: bold; font-size: 14px">
                    {{ atendimento.created_at_f }}
                  </h5>
                  <small class="text-muted w-100"
                    >Data de entrada no setor</small
                  ></span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-md-12 centered mb-1 clickable"
        style="margin-top: -25px; font-size: 18px"
        @click="toggleFullscreenUser"
      >
        <i
          :class="
            userDataFullscreen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'
          "
        ></i>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card content-crud" style="padding: 15px">
          <div class="row">
            <div class="col-md-1" style="border-right: 1px solid #e4e4e4">
              <div
                class="row pd-item"
                :class="page == 1 ? 'selected' : ''"
                @click="page = 1"
              >
                <div class="col-md-12"><i class="far fa-comment-dots"></i></div>
              </div>
              <div
                class="row pd-item"
                :class="page == 2 ? 'selected' : ''"
                @click="page = 2"
              >
                <div class="col-md-12"><i class="fas fa-heartbeat"></i></div>
              </div>
              <div
                class="row pd-item"
                :class="page == 3 ? 'selected' : ''"
                @click="page = 3"
              >
                <div class="col-md-12"><i class="fas fa-stethoscope"></i></div>
              </div>
              <div
                class="row pd-item"
                :class="page == 4 ? 'selected' : ''"
                @click="page = 4"
              >
                <div class="col-md-12"><i class="fas fa-person-booth"></i></div>
              </div>
            </div>
            <div class="col-md-11">
              <section v-if="page == 1">
                <div class="row">
                  <div class="col-md-12">
                    <h3>Queixa principal</h3>
                  </div>
                  <div class="col-md-12 mt-1">
                    <textarea
                      v-model="atendimento.queixa_principal"
                      class="form-control"
                      id=""
                      cols="20"
                      rows="10"
                      disabled
                    ></textarea>
                  </div>
                </div>
              </section>
              <section v-if="page == 2">
                <div class="row">
                  <div class="col-md-12">
                    <h3>Antropometria</h3>
                  </div>
                  <div class="col-md-2">
                    <label for="">Peso</label>
                    <input
                      disabled
                      type="number"
                      class="form-control"
                      id=""
                      v-model="atendimento.sinais_vitais[0].peso"
                    />
                  </div>
                  <div class="col-md-2">
                    <label for="">Altura</label>
                    <input
                      disabled
                      type="text"
                      class="form-control"
                      id=""
                      v-model="atendimento.sinais_vitais[0].altura"
                      v-mask="'#.##'"
                    />
                  </div>
                  <div class="col-md-2">
                    <label for="">IMC</label>
                    <input
                      disabled
                      type="text"
                      class="form-control"
                      id=""
                      v-model="imc"
                    />
                  </div>
                  <div class="col-ml-1" style="margin-top: -15px">
                    <table class="tabela-imc">
                      <tr>
                        <th
                          v-if="
                            atendimento.sinais_vitais[0].peso &&
                            atendimento.sinais_vitais[0].altura &&
                            imc
                          "
                        >
                          Classificação
                        </th>
                        <th
                          v-if="
                            atendimento.sinais_vitais[0].peso &&
                            atendimento.sinais_vitais[0].altura &&
                            imc
                          "
                        >
                          IMC
                        </th>
                      </tr>
                      <tr
                        style="background-color: #ffff00; color: #000000"
                        v-if="
                          imc < 18.5 &&
                          atendimento.sinais_vitais[0].peso &&
                          atendimento.sinais_vitais[0].altura
                        "
                      >
                        <td>Abaixo do peso</td>
                        <td>Menor que 18,5</td>
                      </tr>
                      <tr
                        style="background-color: #add8e6; color: #000000"
                        v-if="
                          imc >= 18.5 &&
                          imc <= 24.9 &&
                          atendimento.sinais_vitais[0].peso &&
                          atendimento.sinais_vitais[0].altura
                        "
                      >
                        <td>Peso normal</td>
                        <td>18,5 - 24,9</td>
                      </tr>
                      <tr
                        style="background-color: #ffa500; color: #000000"
                        v-if="
                          imc >= 25 &&
                          imc <= 29.9 &&
                          atendimento.sinais_vitais[0].peso &&
                          atendimento.sinais_vitais[0].altura
                        "
                      >
                        <td>Sobrepeso</td>
                        <td>25 - 29,9</td>
                      </tr>
                      <tr
                        style="background-color: #ff4500; color: #000000"
                        v-if="
                          imc >= 30 &&
                          imc <= 34.9 &&
                          atendimento.sinais_vitais[0].peso &&
                          atendimento.sinais_vitais[0].altura
                        "
                      >
                        <td>Obesidade grau 1</td>
                        <td>30 - 34,9</td>
                      </tr>
                      <tr
                        style="background-color: #ff0000; color: #000000"
                        v-if="
                          imc >= 35 &&
                          imc <= 39.9 &&
                          atendimento.sinais_vitais[0].peso &&
                          atendimento.sinais_vitais[0].altura
                        "
                      >
                        <td>Obesidade grau 2</td>
                        <td>35 - 39,9</td>
                      </tr>
                      <tr
                        style="background-color: #8b0000; color: #000000"
                        v-if="
                          imc >= 40 &&
                          atendimento.sinais_vitais[0].peso &&
                          atendimento.sinais_vitais[0].altura
                        "
                      >
                        <td>Obesidade grau 3</td>
                        <td>Maior ou igual a 40</td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-12">
                    <h3>Sinais vitais</h3>
                  </div>
                  <div class="col-md-12 mt-1">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Pressão arterial</th>
                          <th scope="col">Temperatura</th>
                          <th scope="col">FR</th>
                          <th scope="col">FC</th>
                          <th scope="col">Saturação</th>
                          <th scope="col">Glasgow</th>
                          <th scope="col">Glicemia</th>
                          <th scope="col">Intensidade da Dor</th>
                          <th scope="col">Data e Hora Medição</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="sinal in atendimento.sinais_vitais"
                          :key="sinal.id"
                        >
                          <td v-if="sinal.pa_diastolica">
                            {{ sinal.pa_sistolica }}x{{ sinal.pa_diastolica }}
                          </td>
                          <td v-else>Não Informado</td>
                          <td>
                            {{
                              sinal.temperatura
                                ? sinal.temperatura
                                : "Não informado"
                            }}
                          </td>
                          <td>{{ sinal.fr ? sinal.fr : "Não informado" }}</td>
                          <td>{{ sinal.fc ? sinal.fc : "Não informado" }}</td>
                          <td>
                            {{
                              sinal.saturacao
                                ? sinal.saturacao + " / " + sinal.saturacao_tipo
                                : "Não informado"
                            }}
                          </td>
                          <td>
                            {{
                              sinal.glasgow ? sinal.glasgow : "Não informado"
                            }}
                          </td>
                          <td>
                            {{
                              sinal.glicemia
                                ? sinal.glicemia + " / " + sinal.glicemia_tipo
                                : "Não informado"
                            }}
                          </td>
                          <td>
                            {{
                              sinal.intensidade_dor && sinal.intensidade_dor > 0
                                ? sinal.intensidade_dor
                                : "Não informado"
                            }}
                          </td>
                          <td>
                            {{
                              sinal.created_at_f
                                ? sinal.created_at_f
                                : "Não informado"
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-12 mt-1">
                    <div class="row mt-2">
                      <div class="col-md-12">
                        <label class="bold" style="font-size: 14px"
                          >Fluxograma Selecionado</label
                        >
                        <input
                          v-if="
                            atendimento.flowchart_id != null &&
                            atendimento.flowchart != null
                          "
                          type="text"
                          class="form-control"
                          v-model="atendimento.flowchart.title"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section v-if="page == 3">
                <div class="row">
                  <div class="col-md-12">
                    <h3>Classificação de Risco</h3>
                  </div>
                  <div class="col-md-12" style="padding: 35px">
                    <div class="row mb-2">
                      <div class="col-md-12">
                        Classificado como
                        <button
                          :class="
                            classificacaoColor2(atendimento.classificacao)
                          "
                        >
                          {{ classificacaoLabel2(atendimento.classificacao) }}
                        </button>
                      </div>
                    </div>
                    <div
                      class="row flowchart_item"
                      :class="'active'"
                      v-if="atendimento.flowchart_item_id != null"
                    >
                      <div class="col-10 bold fs-18">
                        {{ atendimento.flowchart_item.title }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12"></div>
                </div>
              </section>
              <section v-if="page == 4">
                <div class="row mt-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <h3>Encaminhamento</h3>
                      </div>
                    </div>
                    <div class="row row-setor">
                      <div class="col-md-12 bg-setor">
                        <div class="row">
                          <div class="col-md-10">
                            {{
                              atendimento.specialty
                                ? atendimento.specialty.title
                                : "Não informado"
                            }}
                          </div>
                          <div class="col-md-2 block text-right"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <h3>Setor</h3>
                      </div>
                    </div>
                    <div class="row row-setor">
                      <div class="col-md-12 bg-setor">
                        <div class="row">
                          <div class="col-md-10">
                            {{
                              atendimento.setor
                                ? atendimento.setor.title
                                : "Não informado"
                            }}
                          </div>
                          <div class="col-md-2 block text-right"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <h3>Hipertensão</h3>
                        <toggle-button
                          v-model="atendimento.sinais_vitais[0].hipertensao"
                          color="#15488A"
                          :height="25"
                          disabled
                        />
                      </div>
                      <div class="col-md-6">
                        <h3>Diabetes</h3>
                        <toggle-button
                          v-model="atendimento.sinais_vitais[0].diabetes"
                          color="#15488A"
                          :height="25"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <h3>Alergias</h3>
                      </div>
                    </div>
                    <div class="row row-setor">
                      <div class="col-md-12 bg-setor">
                        <div class="row">
                          <div class="col-md-10">
                            {{
                              atendimento.sinais_vitais[0].alergias
                                ? atendimento.sinais_vitais[0].alergias
                                : "Não informado"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <h3>Necessidades Especiais</h3>
                      </div>
                    </div>
                    <div class="row row-setor">
                      <div class="col-md-12 bg-setor">
                        <div class="row">
                          <div class="col-md-10">
                            {{
                              atendimento.necessidades_especiais
                                ? atendimento.necessidades_especiais
                                : "Não informado"
                            }}
                          </div>
                          <div class="col-md-2 block text-right"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <h3>Razão da entrada</h3>
                      </div>
                    </div>
                    <div class="row row-setor">
                      <div class="col-md-12 bg-setor">
                        <div class="row">
                          <div class="col-md-10">
                            {{
                              atendimento.razao
                                ? atendimento.razao.title
                                : "Não informado"
                            }}
                          </div>
                          <div class="col-md-2 block text-right"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-12">
                        <h3>Observação</h3>
                      </div>
                    </div>
                    <div class="row row-setor">
                      <div class="col-md-12 bg-setor">
                        <div class="row">
                          <div class="col-md-10">
                            {{
                              atendimento.observacao
                                ? atendimento.observacao
                                : "Não informado"
                            }}
                          </div>
                          <div class="col-md-2 block text-right"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div class="row">
                <div class="col-md-12 mt-2 block text-right">
                  <div class="col-md-12">
                    <div class="block text-right">
                      <button
                        class="btn btn-back mr-1"
                        @click="page--"
                        v-if="page > 1"
                      >
                        <i class="fa fa-arrow-left"></i> Página Anterior
                      </button>
                      <button
                        class="btn btn-add"
                        v-if="page < 4"
                        @click="nextPage"
                      >
                        Próxima Página
                        <i class="fa fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userDataFullscreen: true,
      page: 1,
      triage: {},
      flowcharts: [],
      setores: [],
      specialties: [],
      flowchart_selected: {},
      atendimento: {
        sinais: {
          saturacao_tipo: "Ar Atmosférico",
          glicemia_tipo: "Pós-prandial",
          classificacao: 1,
          pa_sistolica: 0,
          pa_diastolica: 0,
          temperatura: 0,
          saturacao: 0,
          glicemia: 0,
          alergias_btn: 0,
          intensidade_dor: 0,
        },
        paciente: {
          nome_social: "",
        },
      },
    };
  },
  components: {},
  methods: {
    nextPage() {
      const self = this;
      self.page++;
    },
    toggleFullscreenUser() {
      const self = this;

      if (self.userDataFullscreen) {
        self.userDataFullscreen = false;
      } else {
        self.userDataFullscreen = true;
      }
    },
    getEntity: function (id) {
      const self = this;
      let api =
        self.$store.state.api +
        "atendimentos/" +
        id +
        "?with[]=flowchart&with[]=flowchart_item&with[]=setor&with[]=specialty&with[]=razao&with[]=sinais_vitais&with[]=paciente&with[]=origin";

      axios
        .get(api, self.atendimento)
        .then((response) => {
          self.atendimento = response.data.data[0];
          self.atendimento.inicio_classificacao = moment().format(
            "YYYY-MM-DD HH:mm:ss"
          );

          self.atendimento.sinais_vitais[0].hipertensao =
            self.atendimento.sinais_vitais[0].hipertensao == 1 ? true : false;
          self.atendimento.sinais_vitais[0].diabetes =
            self.atendimento.sinais_vitais[0].diabetes == 1 ? true : false;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    classificacaoLabel2(classificacao) {
      if (classificacao == 1) {
        return "Vermelho";
      }

      if (classificacao == 2) {
        return "Laranja";
      }

      if (classificacao == 3) {
        return "Amarelo";
      }

      if (classificacao == 4) {
        return "Verde";
      }

      if (classificacao == 5) {
        return "Azul";
      }

      if (classificacao == 6) {
        return "Branco";
      }

      if (classificacao == 999) {
        return "Cancelado";
      }

      if (!classificacao) {
        return "Não Classificado";
      }
    },
    classificacaoColor2(classificacao) {
      if (classificacao == 1) {
        return "btn-classificacao vermelho";
      }

      if (classificacao == 2) {
        return "btn-classificacao laranja";
      }

      if (classificacao == 3) {
        return "btn-classificacao amarelo";
      }

      if (classificacao == 4) {
        return "btn-classificacao verde";
      }

      if (classificacao == 5) {
        return "btn-classificacao azul";
      }

      if (classificacao == 6) {
        return "btn-classificacao branco";
      }

      if (classificacao == 999) {
        return "btn-classificacao vermelho";
      }

      if (!classificacao) {
        return "btn-classificacao cinza";
      }
    },
  },
  computed: {
    imc() {
      var imc =
        this.atendimento.sinais_vitais[0].peso /
        (this.atendimento.sinais_vitais[0].altura *
          this.atendimento.sinais_vitais[0].altura);
      return Math.round(imc);
    },
    classificacaoLabel() {
      const self = this;

      if (self.atendimento.classificacao == 1) {
        return "Vermelho";
      }

      if (self.atendimento.classificacao == 2) {
        return "Laranja";
      }

      if (self.atendimento.classificacao == 3) {
        return "Amarelo";
      }

      if (self.atendimento.classificacao == 4) {
        return "Verde";
      }

      if (self.atendimento.classificacao == 5) {
        return "Azul";
      }

      if (self.atendimento.classificacao == 6) {
        return "Branco";
      }

      if (self.atendimento.classificacao == 999) {
        return "Cancelado";
      }

      if (!self.atendimento.classificacao) {
        return "Não Classificado";
      }
    },
    classificacaoColor() {
      const self = this;

      if (self.atendimento.classificacao == 1) {
        return "border-left: 8px solid rgb(252, 3, 3)";
      }

      if (self.atendimento.classificacao == 2) {
        return "border-left: 8px solid rgb(252, 123, 3)";
      }

      if (self.atendimento.classificacao == 3) {
        return "border-left: 8px solid rgb(245, 237, 2)";
      }

      if (self.atendimento.classificacao == 4) {
        return "border-left: 8px solid #00923F";
      }

      if (self.atendimento.classificacao == 5) {
        return "border-left: 8px solid #180092";
      }

      if (self.atendimento.classificacao == 6) {
        return "border-left: 8px solid #e2e2e2";
      }

      if (self.atendimento.classificacao == 999) {
        return "border-left: 8px solid rgb(252, 3, 3)";
      }

      if (!self.atendimento.classificacao) {
        return "border-left: 8px solid #858181";
      }
    },
    diffIdade() {
      const self = this;

      var a = moment();
      var b = moment(self.atendimento.paciente.data_nascimento);

      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");

      if (years < 100) {
        return years + " anos, " + months + " meses, " + days + " dias";
      }
    },
  },
  mounted() {
    const self = this;

    $('[data-toggle="tooltip"]').tooltip();

    let id = self.$route.params.id;
    if (id) {
      self.getEntity(id);
    }
  },
  created() {},
};
</script>

<style scoped>
.bg-setor {
  min-height: 55px;
  background-color: #eaeaea;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: 0.4s;
}
.bg-setor:hover {
  background-color: #c1c1c1;
}
.row-setor {
  padding: 15px;
}

.tabela-imc {
  width: 100%;
  max-width: 600px;
  margin: 0;
  text-align: center;
  font-weight: bold;
}

.tabela-imc th,
.tabela-imc td {
  padding: 10px;
}
</style>