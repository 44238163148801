import Crud from '../views/Protocol/Crud'
import Entity from '../views/Protocol/Add'

let routes = {
    path: '/protocols',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/protocols/add',
            name: 'NewProtocol',
            component: Entity,
            meta: {
                pageTitle: 'Protocolo',
                access_nivel: ['6', '2'],
                breadcrumb: [
                    {
                        text: 'Adicionar Protocolo',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/protocols/edit/:id?',
            name: 'EditProtocol',
            component: Entity,
            meta: {
                pageTitle: 'Protocolo',
                access_nivel: ['6', '2'],
                breadcrumb: [
                    {
                        text: 'Editar Protocolo',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/protocols',
            name: 'list-protocols',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Protocolos',
                access_nivel: ['6', '2'],
                breadcrumb: [
                    {
                        text: 'Protocolos',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
