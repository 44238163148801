import Crud from '../views/Triage/Index'
import CrudFinished from '../views/Triage/IndexFinished'
import CrudCancel from '../views/Triage/IndexCancel'
import Triage from '../views/Triage/Triage'
import Entity from '../views/Triage/Entity'
import View from '../views/Triage/View'

let routes = {
    path: '/triage',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/triage',
            name: 'list-triage',
            component: Crud,
            meta: {
                pageTitle: 'Triagem de Pacientes',
                breadcrumb: [
                    {
                        text: 'Pacientes na Fila',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/triage/finished',
            name: 'list-triage-finished',
            component: CrudFinished,
            meta: {
                pageTitle: 'Triagem de Pacientes',
                breadcrumb: [
                    {
                        text: 'Atendimentos Finalizados',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/triage/cancel',
            name: 'list-triage-cancel',
            component: CrudCancel,
            meta: {
                pageTitle: 'Triagem de Pacientes',
                breadcrumb: [
                    {
                        text: 'Atendimentos Cancelados',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/triage/add',
            name: 'add-attendance',
            component: Entity,
            meta: {
                pageTitle: 'Triagem de Pacientes',
                breadcrumb: [
                    {
                        text: 'Novo Atendimento',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/triage/edit/:id?',
            name: 'add-attendance',
            component: Entity,
            meta: {
                pageTitle: 'Triagem de Pacientes',
                breadcrumb: [
                    {
                        text: 'Editar Atendimento',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/triage/view/:id?',
            name: 'view-attendance',
            component: View,
            meta: {
                pageTitle: 'Triagem de Pacientes',
                breadcrumb: [
                    {
                        text: 'Visualizar Atendimento',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/triage/flowchart/:id?',
            name: 'triage-flowchart',
            component: Triage,
            meta: {
                pageTitle: 'Triagem do Paciente',
                breadcrumb: [
                    {
                        text: 'Pacientes na Fila',
                        active: true,
                    },
                    {
                        text: 'Triagem do Paciente',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
