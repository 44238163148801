import Crud from '../views/Cbo/Crud'
import Entity from '../views/Cbo/Add'

let routes = {
    path: '/cbos',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/cbos/add',
            name: 'NewCbo',
            component: Entity,
            meta: {
                pageTitle: 'CBO',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Adicionar CBO',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/cbos/edit/:id?',
            name: 'EditCbo',
            component: Entity,
            meta: {
                pageTitle: 'CBO',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'Editar CBO',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/cbos',
            name: 'list-cbos',
            component: Crud,
            meta: {
                pageTitle: 'Lista de CBOS',
                access_nivel: ['6'],
                breadcrumb: [
                    {
                        text: 'CBOS',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
