<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card content-crud">
          <div class="ibox-title" style="padding: 16px">
            <h3>Lista de Finalizados</h3>
          </div>
          <div class="ibox-content">
            <div class="row" style="padding: 0px 0px 16px 16px">
              <div class="col-md-3">
                <label>ID</label>
                <input type="text" class="form-control" v-model="filtro.id" />
              </div>
              <div class="col-md-3">
                <label>Nome do Paciente</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="filtro.nome_paciente"
                />
              </div>
              <div class="col-md-3">
                <label>Senha</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="filtro.senha"
                />
              </div>
              <div class="col-md-3">
                <label>Data do Ínicio da Triagem</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="filtro.inicio_classificacao"
                />
              </div>
              <div class="col-md-3 mt-1">
                <label>Data do Fim da Triagem</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="filtro.fim_classificacao"
                />
              </div>
              <div class="col-md-3 mt-1">
                <label>Data da Entrada no Setor</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="filtro.created_at"
                />
              </div>
              <div class="col-md-3 mt-1">
                <label>Fluxogramas</label>
                <select class="form-control" v-model="filtro.flowchart_id">
                  <option value=""></option>
                  <option
                    v-for="flowchart in flowcharts"
                    :key="flowchart.id"
                    :value="flowchart.id"
                  >
                    {{ flowchart.title }}
                  </option>
                </select>
              </div>
              <div class="col-md-3 mt-1">
                <label>Protocolos</label>
                <select class="form-control" v-model="filtro.flowchart_item_id">
                  <option value=""></option>
                  <option
                    v-for="flowchart_item in flowchart_items"
                    :key="flowchart_item.id"
                    :value="flowchart_item.id"
                  >
                    {{ flowchart_item.title }}
                  </option>
                </select>
              </div>
              <div class="col-md-3 mt-1">
                <label>Classificação</label>
                <select class="form-control" v-model="filtro.classificacao">
                  <option value=""></option>
                  <option value="1">Vermelho</option>
                  <option value="2">Laranja</option>
                  <option value="3">Amarelo</option>
                  <option value="4">Verde</option>
                  <option value="5">Azul</option>
                  <option value="6">Branco</option>
                </select>
              </div>
              <div class="row" style="margin-top: 40px">
                <div class="col-md-2">
                  <button class="btn btn-primary" @click="getEntity">
                    Filtrar
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive-sm">
                  <table
                    role="table"
                    aria-busy="false"
                    aria-colcount="4"
                    class="table b-table"
                  >
                    <thead role="rowgroup" class="">
                      <tr role="row" class="">
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="1"
                          class=""
                        >
                          <div>ID</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="1"
                          class=""
                        >
                          <div>Classificação</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="1"
                          class=""
                        >
                          <div>Senha</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="2"
                          class=""
                        >
                          <div>Nome</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="3"
                          class=""
                        >
                          <div>Fluxograma</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="4"
                          class=""
                        >
                          <div>Data/Hora no Setor</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="4"
                          class=""
                        >
                          <div>Início da Triagem</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="4"
                          class=""
                        >
                          <div>Fim da Triagem</div>
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          aria-colindex="4"
                          class=""
                        >
                          <div>Ações</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup">
                      <tr
                        role="row"
                        class=""
                        v-for="atendimento in atendimentos"
                        :key="atendimento.id"
                      >
                        <td aria-colindex="1" role="cell" class="">
                          {{ atendimento.id }}
                        </td>
                        <td aria-colindex="1" role="cell" class="">
                          <router-link
                            :to="'/triage/view/' + atendimento.id"
                            tag="button"
                            :class="
                              classificacaoColor(atendimento.classificacao)
                            "
                          >
                            {{ classificacaoLabel(atendimento.classificacao) }}
                          </router-link>
                        </td>
                        <td aria-colindex="1" role="cell" class="">
                          {{ atendimento.senha }}
                        </td>
                        <td aria-colindex="2" role="cell" class="">
                          {{
                            atendimento.paciente
                              ? atendimento.paciente.nome_social
                                ? atendimento.paciente.nome_social
                                : atendimento.paciente.nome_paciente
                              : "Informação não disponível"
                          }}
                        </td>
                        <td aria-colindex="3" role="cell" class="">
                          {{
                            atendimento.flowchart
                              ? atendimento.flowchart.title + " / "
                              : "BRANCO"
                          }}
                          {{
                            atendimento.classificacao == 5
                              ? "AZUL"
                              : atendimento.flowchart_item
                              ? atendimento.flowchart_item.title
                              : ""
                          }}
                        </td>
                        <td aria-colindex="4" role="cell" class="">
                          {{ atendimento.created_at_f }}
                        </td>
                        <td aria-colindex="4" role="cell" class="">
                          {{ atendimento.inicio_classificacao_f }}
                        </td>
                        <td aria-colindex="4" role="cell" class="">
                          {{ atendimento.fim_classificacao_f }}
                        </td>
                        <td aria-colindex="4" role="cell" class="">
                          <router-link
                            :to="'/triage/view/' + atendimento.id"
                            tag="button"
                            class="btn btn-crud info"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Visualizar Atendimento"
                          >
                            <i class="fas fa-eye"></i>
                          </router-link>
                          <button
                            class="btn btn-crud edit"
                            @click="imprimirFicha(atendimento)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Imprimir Ficha de Classificação"
                          >
                            <i class="fas fa-print"></i>
                          </button>
                          <br />
                          <button
                            class="btn btn-crud edit"
                            @click="imprimirDeclaracao(atendimento)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Imprimir Declaração"
                          >
                            <i class="fas fa-notes-medical"></i>
                          </button>
                          <button
                            class="btn btn-crud delete"
                            @click="reclassificar(atendimento)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Reclassificar"
                          >
                            <i class="fas fa-sync-alt"></i>
                          </button>
                        </td>
                      </tr>
                      <tr v-if="atendimentos.length == 0">
                        <td colspan="8" class="centered mt-2">
                          Nenhum atendimento na fila de triagem.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="col-md-6 mx-auto d-flex justify-content-center align-items-center"
              >
                <pagination
                  :records="total"
                  :options="options"
                  :per-page="15"
                  @paginate="getEntity"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Pagination } from "vue-pagination-2";
export default {
  data() {
    return {
      atendimentos: [],
      flowcharts: [],
      flowchart_items: [],
      filtro: {},
      options: {
        texts: {
          count: "Mostrando {from} de {to} até {count} de registros",
        },
        chunk: 4,
        chunksNavigation: "fixed",
      },
      total: 0,
      page: 1,
    };
  },
  components: { Pagination },
  methods: {
    reclassificar(obj) {
      const self = this;

      swal({
        title: "Tem certeza?",
        text: "O paciente será reclassificado.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim, prosseguir!",
        showLoaderOnConfirm: true,
        buttons: {
          catch: {
            text: "Não",
            value: "cancel",
            className: "btn-danger",
          },
          confirm: {
            text: "Sim",
            value: "confirm",
          },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            swal("OK", "Prossiga na classificação", "info");
            break;

          case "confirm":
            let api = self.$store.state.api + "atendimentos/reclassificar";

            self.$loading(true);

            axios
              .post(api, obj)
              .then((response) => {
                var data = response.data;
                self.$loading(false);
                swal("OK", "Prossiga na classificação", "success");
                self.$router.push("/triage/flowchart/" + data.id);
              })
              .catch((error) => {
                self.$message(null, error.response.data, "error");
                self.$loading(false);
              });

            break;
        }
      });
    },
    imprimirFicha(obj) {
      const self = this;
      let url =
        self.$store.state.url +
        "atendimento/impressao/" +
        obj.id +
        "/" +
        self.user.id;
      window.open(url, "_blank").focus();
    },
    imprimirDeclaracao(obj) {
      const self = this;
      let url =
        self.$store.state.url +
        "atendimento/declaracao/impressao/" +
        obj.id +
        "/" +
        self.user.id;
      window.open(url, "_blank").focus();
    },
    classificacaoLabel(classificacao) {
      if (classificacao == 1) {
        return "Vermelho";
      }

      if (classificacao == 2) {
        return "Laranja";
      }

      if (classificacao == 3) {
        return "Amarelo";
      }

      if (classificacao == 4) {
        return "Verde";
      }

      if (classificacao == 5) {
        return "Azul";
      }

      if (classificacao == 6) {
        return "Branco";
      }

      if (!classificacao) {
        return "Não Classificado";
      }
    },
    classificacaoColor(classificacao) {
      if (classificacao == 1) {
        return "btn-classificacao vermelho";
      }

      if (classificacao == 2) {
        return "btn-classificacao laranja";
      }

      if (classificacao == 3) {
        return "btn-classificacao amarelo";
      }

      if (classificacao == 4) {
        return "btn-classificacao verde";
      }

      if (classificacao == 5) {
        return "btn-classificacao azul";
      }

      if (classificacao == 6) {
        return "btn-classificacao branco";
      }

      if (!classificacao) {
        return "btn-classificacao cinza";
      }
    },
    diffIdade(data_nascimento) {
      const self = this;

      var a = moment();
      var b = moment(data_nascimento);

      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");

      if (years < 100) {
        return years + " anos, " + months + " meses, " + days + " dias";
      }
    },
    getFlowcharts: function () {
      const self = this;
      let api = self.$store.state.api + "flowcharts?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.flowcharts = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getFlowchartItems: function () {
      const self = this;
      let api =
        self.$store.state.api + "flowchart_items?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.flowchart_items = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEntity: function (page) {
      const self = this;

      self.page = page;

      let api =
        self.$store.state.api +
        "get-atendimentos?status=1&with[]=flowchart&with[]=flowchart_item&with[]=razao&with[]=paciente&page=" +
        self.page;

      self.$loading(true);

      axios
        .post(api, self.filtro)
        .then((response) => {
          self.atendimentos = response.data.data;
          self.total = response.data.total;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },

    search: _.debounce(function (e) {
      this.getEntity();
    }, 800),
    filtered_ic(date) {
      this.inicio_classificacao = date;
    },
    filtered_fc(date) {
      this.fim_classificacao = date;
    },
    filtered_de(date) {
      this.created_at = date;
    },
    // getEntity: function (id) {
    //   const self = this;
    //   let api =
    //     self.$store.state.api +
    //     "atendimentos?status=1&with[]=flowchart&with[]=flowchart_item";

    //   axios
    //     .get(api, self.atendimento)
    //     .then((response) => {
    //       self.atendimentos = response.data.data;
    //     })
    //     .catch((error) => {
    //       self.$message(null, error.response.data, "error");
    //     });
    // },
  },
  mounted() {
    const self = this;

    self.getEntity();
    self.getFlowchartItems();
    self.getFlowcharts();

    setTimeout(function () {
      self.user = self.$store.state.user;
    }, 200);

    // setTimeout(function () {
    //   $(function () {
    //     $('[data-toggle="tooltip"]').tooltip();
    //   });
    // }, 2000);
  },
  created() {},
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
</style>
