<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <section v-if="page == 1">
                <div class="row">
                  <div class="col-md-12">
                    <label><span>*</span> Título</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="flowchart.title"
                    />
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-6">
                    <label>Especialidades</label>
                    <v-select
                      id="mySelect"
                      :options="specialties"
                      label="title"
                      v-model="specialty_selecionado"
                    ></v-select>
                  </div>
                  <div class="col-md-3 mt-1">
                    <button
                      class="btn btn-sm btn-primary"
                      style="margin-top: 10px"
                      @click="incluirEspecialidade"
                    >
                      <i class="fas fa-plus"></i> Incluir Especialidade
                    </button>
                  </div>
                </div>
                <hr />
                <div class="row" v-if="flowchart.specialties.length > 0">
                  <div class="col-md-12">
                    <div class="col-md-12">
                      <table class="table table-bordered" style="width: 100%">
                        <thead>
                          <tr>
                            <th scope="col" style="width: 100%">Nome</th>
                            <th scope="col" style="width: 100%">Ação</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="specialty in flowchart.specialties"
                            :key="specialty.id"
                          >
                            <td>
                              {{ specialty.title }}
                            </td>
                            <td
                              class="centered clickable"
                              @click="removeEspecialidade(index)"
                            >
                              <i class="fas fa-trash-alt"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row centered" v-else>
                  <div class="col-md-12">
                    Nenhuma especialidade vinculada, selecione uma especialidade
                    e clique em
                    <strong>"+ Incluir Especialidade"</strong>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4">
                    <label>Sexo</label>
                    <select class="form-control" v-model="flowchart.gender">
                      <option value="0">Ambos</option>
                      <option value="1">Masculino</option>
                      <option value="2">Feminino</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label> Faixa étaria de</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="flowchart.in"
                    />
                  </div>
                  <div class="col-md-4">
                    <label> Faixa étaria até</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="flowchart.to"
                    />
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-4">
                    <label><span>*</span> Protocolo</label>
                    <v-select
                      :options="protocols"
                      label="title"
                      :reduce="(protocol) => protocol.id"
                      v-model="flowchart.protocol_id"
                    ></v-select>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-md-6">
                    <label><span>*</span> Veja também</label>
                    <textarea
                      class="form-control"
                      cols="30"
                      rows="10"
                      v-model="flowchart.view_more"
                    ></textarea>
                  </div>
                  <div class="col-md-6">
                    <label><span>*</span> Notas do fluxograma</label>
                    <textarea
                      class="form-control"
                      cols="30"
                      rows="10"
                      v-model="flowchart.notes"
                    ></textarea>
                  </div>
                </div>
              </section>

              <section v-if="page == 2">
                <div class="row mt-2">
                  <div
                    class="col-md-12"
                    style="font-weight: bold; font-size: 16px"
                  >
                    Montagem do Fluxograma
                  </div>
                  <div class="col-md-12">
                    <button
                      type="button"
                      class="btn btn-primary"
                      style="float: right; margin-top: -5px"
                      @click="newItem"
                    >
                      Adicionar Discriminador
                    </button>
                  </div>
                </div>
                <div class="row mt-2" v-if="flowchart.items.length > 0">
                  <div class="col-md-3"><span>*</span> Título</div>
                  <div class="col-md-4"><span>*</span> Explicação</div>
                  <div class="col-md-2">Tipo</div>
                  <div class="col-md-2"><span>*</span> Cor</div>
                </div>
                <div class="row mt-3 mb-4 centered" v-else>
                  <div class="col-md-12">
                    Nenhum item adastrado, clique em
                    <strong>Adicionar Discriminador</strong>, para adicionar um
                    novo.
                  </div>
                </div>
                <div
                  class="row mt-1"
                  v-for="(item, index) in flowchart.items"
                  :key="index"
                >
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="item.title"
                    />
                  </div>
                  <div class="col-md-4">
                    <input
                      type="text"
                      class="form-control"
                      v-model="item.explicacao"
                    />
                  </div>
                  <div class="col-md-2">
                    <select v-model="item.type" class="form-control">
                      <option value="G">Escala de Glasgow</option>
                      <option value="GC">Glicemia Capilar</option>
                      <option value="P">Pressão</option>
                      <option value="R">Régua de dor</option>
                      <option value="S">Saturação</option>
                      <option value="T">Temperatura</option>
                      <option value="B">Pulso</option>
                      <option value="Z">Sepse</option>
                    </select>
                  </div>
                  <div class="col-md-2" style="margin-top: 5px">
                    <button
                      :class="
                        item.color_id == 1
                          ? 'btn-color red-selected'
                          : 'btn-color red'
                      "
                      @click="item.color_id = 1"
                    ></button>
                    <button
                      :class="
                        item.color_id == 2
                          ? 'btn-color orange-selected'
                          : 'btn-color orange'
                      "
                      @click="item.color_id = 2"
                    ></button>
                    <button
                      :class="
                        item.color_id == 3
                          ? 'btn-color yellow-selected'
                          : 'btn-color yellow'
                      "
                      @click="item.color_id = 3"
                    ></button>
                    <button
                      :class="
                        item.color_id == 4
                          ? 'btn-color green-selected'
                          : 'btn-color green'
                      "
                      @click="item.color_id = 4"
                    ></button>
                  </div>
                  <div class="col-md-1">
                    <button
                      class="btn btn-crud delete"
                      style="margin-top: 5px"
                      type="button"
                      @click="removeItem(index)"
                    >
                      <i
                        class="far fa-trash-alt"
                        data-toggle="tooltip"
                        title="Excluir"
                      ></i>
                    </button>
                  </div>
                </div>
              </section>

              <section v-if="page == 3">
                <div
                  class="col-md-12"
                  style="font-weight: bold; font-size: 18px; text-align: center"
                >
                  Revisar Fluxograma
                </div>
                <div
                  class="col-md-12 mt-2"
                  style="font-weight: bold; font-size: 25px; text-align: center"
                >
                  {{ flowchart.title }}
                </div>
                <div
                  class="row"
                  v-if="
                    flowchart.items.filter((color) => color.color_id == 1)
                      .length > 0
                  "
                >
                  <div class="col-md-5">
                    <div id="triangulo" align="center">
                      <div
                        id="triangulo-para-cima"
                        style="border-bottom-color: #fc0303"
                      ></div>
                      <div
                        id="triangulo-para-baixo"
                        style="border-top-color: #fc0303"
                      ></div>
                    </div>
                    <div class="card-fluxogram">
                      <span
                        v-for="(item, index) in flowchart.items.filter(
                          (color) => color.color_id == 1
                        )"
                        :key="index"
                      >
                        {{ item.title }} <br />
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <img src="@/assets/images/seta.png" class="seta-direita" />
                  </div>
                  <div class="col-md-3">
                    <div class="box red">Vermelho</div>
                  </div>
                </div>
                <div
                  class="row"
                  style="max-height: 130px"
                  v-if="
                    flowchart.items.filter((color) => color.color_id == 1)
                      .length > 0
                  "
                >
                  <div class="col-md-5" align="center">
                    <img
                      src="@/assets/images/seta_baixo.png"
                      class="seta-baixo"
                    />
                  </div>
                </div>
                <div
                  class="row"
                  v-if="
                    flowchart.items.filter((color) => color.color_id == 2)
                      .length > 0
                  "
                >
                  <div class="col-md-5">
                    <div id="triangulo" align="center">
                      <div
                        id="triangulo-para-cima"
                        style="border-bottom-color: #fc7b03"
                      ></div>
                      <div
                        id="triangulo-para-baixo"
                        style="border-top-color: #fc7b03"
                      ></div>
                    </div>
                    <div class="card-fluxogram">
                      <span
                        v-for="(item, index) in flowchart.items.filter(
                          (color) => color.color_id == 2
                        )"
                        :key="index"
                      >
                        {{ item.title }} <br />
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <img src="@/assets/images/seta.png" class="seta-direita" />
                  </div>
                  <div class="col-md-3">
                    <div class="box orange">Laranja</div>
                  </div>
                </div>
                <div
                  class="row"
                  style="max-height: 130px"
                  v-if="
                    flowchart.items.filter((color) => color.color_id == 2)
                      .length > 0
                  "
                >
                  <div class="col-md-5" align="center">
                    <img
                      src="@/assets/images/seta_baixo.png"
                      class="seta-baixo"
                    />
                  </div>
                </div>
                <div
                  class="row"
                  v-if="
                    flowchart.items.filter((color) => color.color_id == 3)
                      .length > 0
                  "
                >
                  <div class="col-md-5">
                    <div id="triangulo" align="center">
                      <div
                        id="triangulo-para-cima"
                        style="border-bottom-color: #f5ed02"
                      ></div>
                      <div
                        id="triangulo-para-baixo"
                        style="border-top-color: #f5ed02"
                      ></div>
                    </div>
                    <div class="card-fluxogram">
                      <span
                        v-for="(item, index) in flowchart.items.filter(
                          (color) => color.color_id == 3
                        )"
                        :key="index"
                      >
                        {{ item.title }} <br />
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <img src="@/assets/images/seta.png" class="seta-direita" />
                  </div>
                  <div class="col-md-3">
                    <div class="box yellow">Amarelo</div>
                  </div>
                </div>
                <div
                  class="row"
                  style="max-height: 130px"
                  v-if="
                    flowchart.items.filter((color) => color.color_id == 3)
                      .length > 0
                  "
                >
                  <div class="col-md-5" align="center">
                    <img
                      src="@/assets/images/seta_baixo.png"
                      class="seta-baixo"
                    />
                  </div>
                </div>
                <div
                  class="row"
                  v-if="
                    flowchart.items.filter((color) => color.color_id == 4)
                      .length > 0
                  "
                >
                  <div class="col-md-5">
                    <div id="triangulo" align="center">
                      <div
                        id="triangulo-para-cima"
                        style="border-bottom-color: #00923f"
                      ></div>
                      <div
                        id="triangulo-para-baixo"
                        style="border-top-color: #00923f"
                      ></div>
                    </div>
                    <div class="card-fluxogram">
                      <span
                        v-for="(item, index) in flowchart.items.filter(
                          (color) => color.color_id == 4
                        )"
                        :key="index"
                      >
                        {{ item.title }} <br />
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <img src="@/assets/images/seta.png" class="seta-direita" />
                  </div>
                  <div class="col-md-3">
                    <div class="box green">Verde</div>
                  </div>
                </div>

                <div
                  class="row"
                  style="max-height: 130px"
                  v-if="
                    flowchart.items.filter((color) => color.color_id == 4)
                      .length > 0
                  "
                >
                  <div class="col-md-5" align="center">
                    <img
                      src="@/assets/images/seta_baixo.png"
                      class="seta-baixo"
                    />
                  </div>
                </div>
                <div
                  class="row"
                  v-if="
                    flowchart.items.filter((color) => color.color_id == 4)
                      .length > 0
                  "
                >
                  <div class="col-md-5" align="center">
                    <div class="final-box blue">Azul</div>
                  </div>
                </div>
                <div class="row" v-else>
                  <div class="col-md-5" align="center">
                    <div class="final-box green">Verde</div>
                  </div>
                </div>
              </section>

              <section>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <div class="block text-right">
                      <button
                        class="btn btn-back mr-1"
                        @click="page--"
                        v-if="page > 1"
                      >
                        <i class="fa fa-arrow-left"></i> Etapa Anterior
                      </button>
                      <button
                        class="btn btn-add"
                        v-if="page < 3"
                        @click="nextPage"
                      >
                        Próxima Etapa
                        <i class="fa fa-arrow-right"></i>
                      </button>
                      <button
                        class="btn btn-add"
                        v-if="page == 3"
                        @click="nextPage"
                      >
                        Salvar Fluxograma
                        <i class="fa fa-save"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      specialties: [],
      flowchart: {
        discriminators: [],
        items: [],
        specialties: [],
      },
      specialty_selecionado: {},
      protocols: [],
      errors: undefined,
      previewImage: null,
    };
  },
  computed: {},
  methods: {
    getSpecialties: function (id) {
      const self = this;
      const api = self.$store.state.api + "specialties?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.specialties = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProtocols: function (id) {
      const self = this;
      const api = self.$store.state.api + "protocols?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.protocols = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    incluirEspecialidade() {
      const self = this;

      if (!self.specialty_selecionado.id) {
        self.$message(null, "Você precisa escolher uma Especialidade", "error");
        return;
      }

      self.flowchart.specialties.push(self.specialty_selecionado);

      self.specialty_selecionado = {};
    },
    nextPage() {
      const self = this;

      self.flowchart.page = self.page;

      self.save();
    },
    newDiscriminator() {
      var discriminator = {
        title: "",
        description: "",
      };

      this.flowchart.discriminators.push(discriminator);
    },
    removeDiscriminator(index) {
      this.flowchart.discriminators.splice(index, 1);
    },
    removeEspecialidade(index) {
      this.flowchart.specialties.splice(index, 1);
    },
    newItem() {
      var item = {
        title: "",
        color_id: "",
      };

      this.flowchart.items.push(item);
    },
    removeItem(index) {
      this.flowchart.items.splice(index, 1);
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "flowcharts";

      let fd = self.flowchart;

      axios
        .post(api, fd)
        .then((response) => {
          if (self.page < 3) {
            self.page++;
          } else {
            self.$message("Sucesso", "Fluxograma criado!", "success");
            self.$router.push("/flowcharts");
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    self.getSpecialties();
    self.getProtocols();
  },
  components: {},
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
.vs__dropdown-toggle {
  padding: 5px 0 20px !important;
}
</style>