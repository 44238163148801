import Crud from '../views/Flowchart/Crud'
import Entity from '../views/Flowchart/Add'
import View from '../views/Flowchart/View'

let routes = {
    path: '/flowcharts',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/flowcharts/add',
            name: 'NewFlowchart',
            component: Entity,
            meta: {
                pageTitle: 'Fluxograma',
                access_nivel: ['6', '2'],
                breadcrumb: [
                    {
                        text: 'Adicionar Fluxograma',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/flowcharts/edit/:id?',
            name: 'EditFlowchart',
            component: Entity,
            meta: {
                pageTitle: 'Fluxograma',
                access_nivel: ['6', '2'],
                breadcrumb: [
                    {
                        text: 'Editar Fluxograma',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/flowcharts/view/:id?',
            name: 'ViewFlowchart',
            access_nivel: ['6'],
            component: View
        },
        {
            path: '/flowcharts',
            name: 'list-flowcharts',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Fluxogramas',
                access_nivel: ['6', '2'],
                breadcrumb: [
                    {
                        text: 'Fluxogramas',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
